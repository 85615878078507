import React, { useEffect, useState } from "react";
import mqtt from "mqtt/dist/mqtt";

function MqttListen() {
  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8081,
  };

  let [msg, setMsg] = useState();
  let [date, setDate] = useState();
  // let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);

  let client = mqtt.connect("mqtt://64.226.92.126", options);
  console.log(client);
  useEffect(() => {
    client.subscribe("/test");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      setMsg(message.toString());
      setDate(Date.now());
    });
  }, [client]);
  return (
    <div>
      <h1>Mqtt Listen check</h1>
      <h1>{date} </h1>
      MasterComponenetFinal + {msg}
    </div>
  );
}

export default MqttListen;
